
import './App.css';
import Main from './Main'


import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div>
        

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/" component={() => <h1>../</h1>}/>
          <Route path="/:location/:itemId/:lang" component={Main} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
