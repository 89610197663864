import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import axios from 'axios'

const httpClient = axios.create({
    baseURL: document.location.hostname == "item-upload.menual.net"
      ? "https://menual.azurewebsites.net"
      : "http://localhost:55836"//"https://menualdev.azurewebsites.net",
    ,headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    }
  });

const serializeJSON = (data) => {
    return Object.keys(data)
        .map(function (keyName) {
            return (
                encodeURIComponent(keyName) + '=' + encodeURIComponent(data[keyName])
            );
        })
        .join('&');
}

const Main = () => {

    const [Item, setItem] = useState(null)
    let { itemId, location, lang } = useParams()
    const [selectedFile, setSelectedFile] = useState();


    const languaje = {
        en: {
            "upload": "Upload"
        },
        he: {
            "upload": "Upload"
        }
    }

    const createFormData = (photo, body) => {
        const data = new FormData();
    
        data.append('photo', photo, photo.name);
    
        Object.keys(body).forEach(key => {
            data.append(key, body[key]);
        });
        console.log('createFormData', data);
        return data;
    };
    

    const getMenuItem = () => {
        console.log("location.hostname", window.location.host)
        console.log("Item", itemId, location)
        
        httpClient.post("/ws/Menu.asmx/GetMenuByID", serializeJSON({
            ItemID: itemId,
            LocationID: location,
            Lang: lang || "he"
        }))
            .then(({ data }) => {
                setItem(data.Item)
            })
            .catch((e) => {
                console.warn("GetMenuByID war", e)
                setItem(null)
            })
    }

    useEffect(() => {

        getMenuItem();
    }, [])

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);

    };

    const sendFile = () => {
        httpClient.post("ws/UploadImage.aspx", createFormData(selectedFile, {
            ID: itemId,
            Token: 'tokenhere',
        }),{
            headers : {
                
            }
        })
        .then(({data}) => {
            console.log("Image Uploaded", data)
            window.location.reload()
        })
        .catch((e) => {
            console.warn("Cant upload image", e)
            alert("Cannot upload image")
        })
    }

    if (!Item) {
        return <h1>Item not found</h1>
    }


    return (
        <div className="container">
            <div className="row" style={{
                marginTop: 50
            }}>

                <h1>{Item.location.name}</h1>
                <h3 style={{
                    color: "#3a3a3",
                    fontWeight: 100
                }}>{Item.name}</h3>
                <img src={selectedFile ? URL.createObjectURL(selectedFile) : Item.Image} style={{
                    height: 300,
                    width: 300,
                    objectFit: "cover"

                }} className="img-thumbnail" alt="..." />
                <div class="mb-3">
                    <label for="formFile" class="form-label">Default file input example</label>
                    <input name="file" class="form-control" onChange={changeHandler} type="file" id="formFile" />
                </div>

                <div class="col-auto">
                    <button onClick={sendFile} type="submit" class="btn btn-primary mb-3">{languaje[lang].upload}</button>
                </div>
            </div>
        </div>
    )
}

export default Main
